

#table-input {
    background-color: var(--ion-background-color, black);
    height: 40px;
    width: 100px;
    border: none;
    border-bottom: 1px solid var(--ion-color-dark, blue);
    color: white;
    font-size: 25px;
    text-align: center;
}

#table-input:focus {
    border-bottom: 1px solid var(--ion-color-dark, blue);
    outline: none;
}

#sum {
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-top: 4px;
}