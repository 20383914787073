#table-input-edit {
    font-size: 25px;
}

#button-text-add {
    font-size: 12px;
}

#add-button {
    --padding-bottom: 0;
    --padding-top: 0;
    --padding-start: 2px;
    --padding-end: 2px;
}